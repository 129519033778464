'use strict';

// ::::::::::::::::::::::::::::::::::::::::::::: file description
// :: 
// :: this is the main file
// :: 
// :: 

// ::::::::::::::::::::::::::::::::::::::::::::: imports

// :::::::::::::::::: dependencies
import $ from 'jquery';

// :::::::::::::::::: utils
import { getBrowserVersion, EventEmitter, Observer, Mapolator } from './util';
import c from './config.js';
import MobileDetect from 'mobile-detect';

// :::::::::::::::::: general
//import UrlAnalyzer from './urlAnalyzer';
//import Navigation from './navigation';
import Sidetitles from './sidetitles';

// ::::::::::::::::::::::::::::::::::::::::::::: variables

// :::::::::::::::::: globals
// jquery
window.$ = $;
// config
window.c = c;
// if you need events from static class
window.staticEvents = new EventEmitter();

// :::::::::::::::::: objects
//const nav = new Navigation();
//const urlAnalyzer = new UrlAnalyzer();
const sides = new Sidetitles();
//const content = new Content();

// ::::::::::::::::::::::::::::::::::::::::::::: events
//window.staticEvents.addListener('navigate', (data) => {
    //nav.navigate(data);
//});

// ::::::::::::::::::::::::::::::::::::::::::::: functions

// :::::::::::::::::: responsive

const resize = () => {
	clearTimeout(window.resizeTimer);
	window.resizeTimer = setTimeout(() => {
		const wr = window.innerWidth/1024.0;
		const hr = window.innerHeight/360.0;
		const ratio = Mapolator(Math.min(hr,wr), 0, 1, 0, 1, true);
		const anti100 = 100.0 / ratio;
		const anti50 = Mapolator(anti100, 100, 200, 0, 50, false);
		const container = $('#container');
        window.ratio = ratio;
        sides.position();

        container.css({
          '-webkit-transform' : 'scale(' + ratio + ')',
          '-moz-transform'    : 'scale(' + ratio + ')',
          '-ms-transform'     : 'scale(' + ratio + ')',
          '-o-transform'      : 'scale(' + ratio + ')',
          'transform'         : 'scale(' + ratio + ')',
          'width'         : anti100 + '%',
          'height'        : anti100 + '%',
          'top'           : '-' + anti50 + '%',
          'left'           : '-' + anti50 + '%',
        });
	}, 100);
}

// :::::::::::::::::: init function

function init() {
	$(window).resize(() => {
        resize();
	});
    //urlAnalyzer.init();
    resize();
    //ReactDOM.render(<App />, document.getElementById('reactor'));
    sides.init();
}

// ::::::::::::::::::::::::::::::::::::::::::::: initialization

$(document).ready(() => {
	init();
  $("#othercontainer").append("<video autoplay='1' muted='true' loop='1' id='bgVideo' class='v' src='./assets/videos/jrkb.mp4'/>");
    const v = document.getElementById('bgVideo');
    v.addEventListener('loadeddata', function() {
        v.play();
    }, false);
    v.load(); 
    v.play();
    setTimeout(() => {
        v.play();
    },4000);
	console.log('You are currently viewing the console output of a web application. Enjoy.');
});

const md = new MobileDetect(window.navigator.userAgent);
if (md.mobile() !== null) {
	console.log('mobile');
  $('body').addClass('mobile');
  $("#sidetitles").css("max-height","calc(100% - "+$("#header").find("p").height()+"px)")
  // $("#header").css("left","0px");
  // $("#header").css("top","0px");

} else {
	console.log('not mobile');
  $('body').addClass('desktop');
}
