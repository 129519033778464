'use strict';


// ::::::::::::::::::::::::::::::::::::::::::::: file description
// :: 
// :: Here are general utility classes collected
// :: getBrowser
// :: 
// :: 


// ::::::::::::::::::::::::::::::::::::::::::::: browser

export const getBrowser = () => {
	const N = navigator.appName;
	const ua = navigator.userAgent;
	let tem;
	let M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
	if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1];
	M = M ? [M[1], M[2]] : [N, navigator.appVersion, '-?'];
	return M[0];
};

export const getBrowserVersion = () => {
	const N = navigator.appName;
	const ua = navigator.userAgent;
	let tem;
	let M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
	if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1];
	M =  M ? [M[1], M[2]] : [N, navigator.appVersion, '-?'];
	return M[1];
};

// ::::::::::::::::::::::::::::::::::::::::::::: data types

export function cEnum(obj) {
	const keysByValue = new Map();
	const EnumLookup = (value) => { keysByValue.get(value); };

	for (const key of Object.keys(obj)) {
		EnumLookup[key] = obj[key];
		keysByValue.set(EnumLookup[key], key);
	}

	// Return a function with all your enum properties attached.
	// Calling the function with the value will return the key.
	return Object.freeze(EnumLookup);
}

let isFunction = function(obj) {  
    return typeof obj == 'function' || false;
};

// ::::::::::::::::::::::::::::::::::::::::::::: math

function clamp(value, outMin, outMax) {
	const min = Math.max(outMin, outMax);
	const max = Math.min(outMin, outMax);
	return Math.min(min, Math.max(max, value));
}

export function Mapolator(value, inMin, inMax, outMin, outMax, bClamp = true) {
	const base = (value - inMin) / (inMax - inMin);
	const mapped = (outMax - outMin) * base + outMin;
	return bClamp ? clamp(mapped, outMin, outMax) : mapped;
}

// ::::::::::::::::::::::::::::::::::::::::::::: data (NOT USED)

export const requestUrl = (requestUrl, callback) => {
    console.log('is called');
	const oReq = new XMLHttpRequest();
	oReq.onload = (e) => { callback(e.target.responseText); };
	oReq.open('GET', c.rootFolder + requestUrl, true);
	oReq.send();
}

// ::::::::::::::::::::::::::::::::::::::::::::: events

export class EventEmitter { 

	constructor() {
	  	this.listeners = new Map();
	}

	addListener(label, callback) {
	  	this.listeners.has(label) || this.listeners.set(label, []);
	  	this.listeners.get(label).push(callback);
	}

	removeListener(label, callback) {  
	    let listeners = this.listeners.get(label),
	        index;

	    if (listeners && listeners.length) {
	        index = listeners.reduce((i, listener, index) => {
	            return (isFunction(listener) && listener === callback) ?
	                i = index :
	                i;
	        }, -1);

	        if (index > -1) {
	            listeners.splice(index, 1);
	            this.listeners.set(label, listeners);
	            return true;
	        }
	    }
	    return false;
	}
	emit(label, ...args) {  
	    let listeners = this.listeners.get(label);
	    if (listeners && listeners.length) {
	        listeners.forEach((listener) => {
	            listener(...args); 
	        });
	        return true;
	    }
	    return false;
	}
}

export class Observer {  
  constructor(id) {
    this.id = id;
  }
  onChange(data) {
    console.log(`${this.id} notified of change:`, data);
  }
}

// ::::::::::::::::::::::::::::::::::::::::::::: css javascript bridge helpers
export const transitionEnd = () => {
  var t,
      el = document.createElement("fakeelement");

  var transitions = {
    "transition"      : "transitionend",
    "OTransition"     : "oTransitionEnd",
    "MozTransition"   : "transitionend",
    "WebkitTransition": "webkitTransitionEnd"
  }

  for (t in transitions){
    if (el.style[t] !== undefined){
      return transitions[t];
    }
  }
}

export const typeWrite = (element, sentence, callback, speed=100, random=400, audio=false) => {
    const letter = sentence.shift();
    element.append(letter);
    if (sentence.length > 0) {
        setTimeout(() => {
            typeWrite(element, sentence, callback, speed, random, audio);
        },speed + Math.random() * random);
    } else {
        callback();
    }
}
