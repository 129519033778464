'use strict'

export default class Sidetitles {
    constructor () {
    }
    init() {

        this.subs = [];
        $.getJSON('../assets/videos/subs.json',(data) => {
            this.subs = data.subs;
        }).done((e) => {
            setTimeout(() => {
                this.start();
            }, 3000);
        });
        this.mom = $('#sidetitles');
        this.time = 0;
    }
    position() {
        this.x = $('#address').width();
        this.y = $('#address').height();
        this.mom.css('right', '0px');
        this.mom.css('bottom', '0px');
        this.mom.css('width', '100%');//this.x + 'px');
        //($(window).height()* (1.0/window.ratio) - this.y) + 'px');
    }
    start() {
        this.activeSub = 0;
        setTimeout(() => {
            this.pushElement(this.subs[this.activeSub]);
        },2000);
    }
    pushLetter(littleBoy, sentence) {
        littleBoy.append(sentence.shift());
        setTimeout(() => {
            if (sentence.length > 0) {
                this.pushLetter(littleBoy, sentence);
            } else {
                if (this.activeSub < this.subs.length) {
                    setTimeout(() => {
                        this.pushElement(this.subs[this.activeSub]);
                    }, 2000);
                } else {
                    this.activeSub = 0;
                    setTimeout(() => {
                        this.pushElement(this.subs[this.activeSub]);
                    }, 2000);
                }
            }
        },10 + Math.random() * 80);
    }
    pushElement(sentence,voice='jrkb'){
        const littleBoy = $(`<p class="zeroHeight"></p>`);
        const sentenceArray = sentence.split('');
        this.mom.append(littleBoy);            
        setTimeout(() => {
            littleBoy.removeClass('zeroHeight');
        },40);
        this.activeSub++;
        this.pushLetter(littleBoy, sentenceArray);
    }
}
